/* Warning: please do not statically import any dependencies here except for static assets like icons */
// import icon from './file-code-solid.svg'
// import { ReactComponent as icon } from './file-code-solid.svg'
import { actions, getAuthState, getState } from '@sparky/framework';
import { TsgInstances } from '@sparky/framework/build/types/framework/indexedArrays';
import { ReadonlyTsgProperties } from '@sparky/framework/build/types/framework/stateTypes';
import { FawkesCloudServicesResponse, FetchStatus } from './data/queryResultsDefinition';
import Logger from './shared/Logger';
import { MfePropertyName, praAppID } from './shared/constants';
import PraUrls from './shared/urls';

export interface LicenseInfoBase {
    'app_id': string,
    'license_type': string,
    'license_expiration'?: string
  }

export interface AddOnInfo {
    license_type: string,
    license_expiration: string,
    in_grace_period: boolean
}

export interface Addon_licenses {
    items: AddOnInfo[];
    in_grace_period: boolean
}

const praNav: object = {
    navigation: [{
    key: 'workflows',
    title: 'Workflows',
    expandable: true,
    path: '/workflows',
    noRBACheck: true,
    exact: false,
    mergeNav: true,
    children: [{
        key: 'privileged-remote-access',
        title: 'Privileged Remote Access',
        path: PraUrls.praBaseUrl,
        noRBACheck: true,
        contentClassName: 'panwds-tw3',
        component: () => import('./Routes'), // any component must be dynamically import like this
        children:[{
            key: 'pra-status',
            title: 'PRA Overview',
            path: PraUrls.praStatus,
            noRBACheck: true,
            contentClassName: 'panwds-tw3',
        }, 
        {
            key: 'pra-portal',
            title: 'PRA Portal',
            path: PraUrls.praPortal,
            noRBACheck: true,
            contentClassName: 'panwds-tw3',
            avail: () => { return availablitySingleton.getIsAvailable()},
        }, {
            key: 'pra-applications',
            title: 'Applications',
            path: PraUrls.praApplications,
            noRBACheck: true,
            contentClassName: 'panwds-tw3',
            avail: () => { return availablitySingleton.getIsAvailable()},
        }, {
            key: 'pra-profiles',
            title: 'PRA Profiles',
            path: PraUrls.praProfiles,
            noRBACheck: true,
            contentClassName: 'panwds-tw3',
            avail: () => { return availablitySingleton.getIsAvailable()},
        }, {
            key: 'pra-active-sessions',
            title: 'Active Connections',
            path: PraUrls.praActiveSessions,
            noRBACheck: true,
            avail: () => { return availablitySingleton.getIsAvailable()},
        }]
    }],
}]
}

export default function init() {
    return async function tsgLoad() {
        try {
            if (await isFeatureEnabled()) {
                return praNav;
            }
        } catch (e) {
            const err = e as FetchStatus;
            Logger.error(
                `AppManagement: Error getting Fawkes cloud services - ${err?.status?.http_status}`
            );
        }
        return undefined
    };
};
 
const checkMfePSIfEnabled = (authStateProperties: ReadonlyTsgProperties | undefined): void => {
    // check MFE property store to see the tenant has access to the privileged remote access features
    const appEnabledProp = authStateProperties?.get(MfePropertyName.praSettings);
    const appEnabled = appEnabledProp !== undefined ? appEnabledProp?.enabled : false
    availablitySingleton.setIsAvailable(appEnabled !== undefined ? appEnabled : false)
}

async function isFeatureEnabled() {
    const licInfo = await sparkyHasLicense();
    LicenseSingleton.setInstance(licInfo);

    const authState = getAuthState();
    const authStateProperties = authState?.properties;

    if (licInfo.items.length > 0) {
        checkMfePSIfEnabled(authStateProperties)
        return true;
    }

    // check if feature is enabled in fawkes features (needed for panorama)
    const { toggles } = authStateProperties?.get(MfePropertyName.fawkesFeatures) || { toggles: [] };
    const enabledAsAFawkesFeature = !!toggles.find(
        (toggle: { t: 1 | 0; fn: string }) => toggle.t === 1 && toggle.fn === 'agentless-access-uda'
    );
    if (enabledAsAFawkesFeature) {
        checkMfePSIfEnabled(authStateProperties)
        return true;
    }

    // check if feature is enabled in fawkes cloud services
    const cloudServicesSettings = authStateProperties?.get(MfePropertyName.fawkesCloudServices);
    const cloudServices = cloudServicesSettings?.[0] as FawkesCloudServicesResponse;
    const isEnabledInCloudServices = !!cloudServices?.mobile_users_agentless_access;
    if (isEnabledInCloudServices) {
        checkMfePSIfEnabled(authStateProperties)
        return true;
    }

    // check if this is a panorama tenant. If it is and we didn't find it above than we know it is not
    // worth checking fawkes for enablement
    const instances = authState?.instances;
    const platformType = instances?.paPlatformType;
    const panoramaManageTenant = platformType === 'onprem';
    if (panoramaManageTenant) {
        checkMfePSIfEnabled(authStateProperties)
        return false;
    }

    // TODO:  This may not be needed because everyone uses MFE Property store so this call is redundant.
    //
    // // check if feature is enabled in fawkes cloud services by calling fawkes endpoint (last resort)
    // const response = await ZGet(PraUrls.serverFawkesCloudServices, '', Products.FAWKES_JWT) as FawkesCloudServicesResponse[];
    // const isEnabledInResponse = !!response?.[0]?.mobile_users_agentless_access;
    // if (isEnabledInResponse) {
    //     return true;
    // }
}

export async function sparkyHasLicense() {
    let in_grace_period = true;
    let validLicense = false;

    // licInfo is local storage for license info.  We don't have context yet so we save it locally.  When we make
    // the capabilities call in AppAccelRoutes (only done once), we get licInfo and store it in context.
    const licInfo: Addon_licenses = {items: [],  in_grace_period: false};
    // LicenseSingleton.getInstance();
    const authState = getAuthState();

    const instances:  Readonly<TsgInstances> = authState?.instances
    if (instances?.length > 0) {
        for (let inst=0, instLen=instances.length; inst < instLen; inst++) {
            const appId = instances[inst]?.app_id; 
            // only check license for prisma access app_ids
            if (appId !== 'prisma_access_edition' && appId !== 'prisma_access_edition_onprem' && 
                appId !== 'prisma_access_panorama' ) { continue; }

            const entitlements: LicenseInfoBase[] = instances?.get(appId)?.entitlements;

            if (entitlements) {
                licInfo.items.length = 0;
                const items: AddOnInfo[] = [];

                for (let i=0, len=entitlements.length; i < len; i++) {
                    const lic = entitlements[i];
                    Logger.info(`license from sparky '${lic.app_id}'`)
                    if (lic.app_id === praAppID) {
                        validLicense = true;
                        const expiration = lic.license_expiration as string;
                        const in_grace = (new Date().getTime()) > (new Date(expiration).getTime());
                        const item: AddOnInfo = {license_type: lic.license_type, license_expiration: expiration, in_grace_period: in_grace}
                        items.push(item)

                        in_grace_period = in_grace_period && !!(item?.in_grace_period);
                    }

                    licInfo.items = items;
                    licInfo.in_grace_period = in_grace_period;
                }

                if (licInfo.items.length > 0) {
                    Logger.info(`found App_Accel license, ${appId}`);
                    validLicense = true;
                    break;
                }
            }
            if (licInfo.items.length > 0) {
                break;
            }
        }
    } else {
        validLicense = false;
        // const licInfo = LicenseSingleton.getInstance();
        licInfo.items = [];
        licInfo.in_grace_period = false;
    }
    Logger.info(`APP_ACCEL has license = ${validLicense}`);

    return licInfo;
}

export const availablitySingleton = (() => {
    return {
        setIsAvailable: (isAvailable: boolean) => {
            actions.setValues({praEnabled: isAvailable})
        },
        getIsAvailable: () => {
            const myState = getState()?.praEnabled;
            return myState ? myState : false;
        }
    };

})();

const LicenseSingleton = (() => {
    let praLicense: Addon_licenses | undefined = undefined;
    return {
        getInstance: (): Addon_licenses => {
            if (praLicense === undefined) {
                praLicense = {items: [],  in_grace_period: false}
            }

            return praLicense;
        },
        setInstance: (licInfo: Addon_licenses) => {
            praLicense = {...licInfo}
        }
    }
})()