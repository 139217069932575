// lengths are based on the following stackoverflow answer 
// https://stackoverflow.com/questions/14402407/maximum-length-of-a-domain-name-without-the-http-www-com-parts
//
import { PraMap } from "../data/queryResultsDefinition";

export const MAX_DOMAIN_LENGTH = 253;
export const MAX_LABEL_LENGTH = 63;
export const MAX_INPUT_LENGTH = 63;
export const MAX_DESCRIPTION_LENGTH = 255;

export const DEV_SPECIAL_TsgID = '1529301731';
export const praAppID = 'add_pra';

export const blueBubbleCss = "pra-inline-flex pra-cursor-pointer pra-px-1 pra-py-0 " + 
                          "pra-flex-col pra-content-center pra-rounded-lg pra-bg-blue-200 ";

export const MENU_STYLES = { width: '20rem', maxWidth: '70vw' };

export const APP_GROUP_MODIFIER = 'GROUP';

export const enum MfePropertyName {
  praSettings = 'privileged_remote_access.settings',
  fawkesFeatures = 'fawkes.features',
  fawkesCloudServices = 'fawkes.cloudServices',
}

export enum ToastAppearance {
    'clear' = 'clear',
    'info' = 'info',
    'warning' = 'warning',
    'error' = 'error',
    'success' = 'success',
}

export interface ToastOptions {
  appearance: ToastAppearance;
  autoDismiss: boolean;
}

export const toastErrorOptions: ToastOptions = {
  appearance: ToastAppearance.error,
  autoDismiss: false,
};

export const toastSuccessOptions: ToastOptions = {
  appearance: ToastAppearance.success,
  autoDismiss: true,
};

export enum PraTabs {
    PraApps = 'PRA_APPS',
    PraAppGroups = 'PRA_GROUPS',
    PraAppPortals = 'PRA_PORTALS'
}

export enum DeletionTypes {
    Applications = "APPLICATIONS",
    Sessions     = "SESSIONS",
}

// API Gee 'go' error codes.  Taken from GitLab sase-accl-controller project on 1/25/24.
//     Full path to code is 'sase-accl-controller/internal/types/errorcodes.go'
export enum GoError {
     ErrForbidden = 1001,                  // http.StatusForbidden
     ErrInvalidInput = 1002,               // http.StatusBadRequest
     ErrCoreAPICallFailed = 1003,          // HTTP status will be populated from the error object returned from coreapiclient.
     ErrInvalidOutput = 1004,              // HTTP status will be populated from the error object returned from coreapiclient.
     ErrInternalServerError = 1005,        // http.StatusInternalServerError
     ErrPreconditionFailed = 1006,         // http.StatusPreconditionFailed
     ErrMethodNotAllowed = 1007,           // http.StatusMethodNotAllowed
     ErrNotFound = 1008,                   // http.StatusNotFound
     ErrServiceUnavailable = 1009,         // http.StatusServiceUnavailable
     ErrDuplicateNotAllowed = 1010,        // http.StatusBadRequest
}


// This list should be occasionally updated to support the latest list of locations from the Fawkes API.
// export const FawkesLocationsNameMap: PraMap<string> = {}
export const FawkesLocationsNameMap: PraMap<string> = {
  "canada-central": "Canada Central",
  "ca-central-1":  "Canada East",
  "canada-west": "Canada West",
  "costa-rica": "Costa Rica",
  "guatemala": "Guatemala",
  "mexico-central": "Mexico Central",
  "mexico-west": "Mexico West",
  "panama": "Panama",
  "us-east-2": "US Central",
  "us-west-3": "US Central West",
  "us-east-1":  "US East",
  "us-northeast": "US Northeast",
  "us-west-2": "US Northwest",
  "us-south": "US South",
  "us-southeast": "US Southeast",
  "us-west-201": "US Southwest",
  "us-west-1": "US West",
  "argentina": "Argentina",
  "bolivia": "Bolivia",
  "brazil-central": "Brazil Central",
  "brazil-east": "Brazil East",
  "sa-east-1": "Brazil South",
  "chile": "Chile",
  "columbia":  "Colombia",
  "ecuador": "Ecuador",
  "paraguay": "Paraguay",
  "peru": "Peru",
  "uruguay": "Uruguay",
  "venezuela": "Venezuela",
  "andorra": "Andorra",
  "austria": "Austria",
  "belarus": "Belarus",
  "belgium": "Belgium",
  "bulgaria": "Bulgaria",
  "croatia": "Croatia",
  "czech-republic": "Czech Republic",
  "denmark": "Denmark",
  "finland": "Finland",
  "eu-west-3": "France North",
  "france-south": "France South",
  "eu-central-1": "Germany Central",
  "germany-north": "Germany North",
  "germany-south": "Germany South",
  "ghana": "Ghana",
  "greece": "Greece",
  "hungary": "Hungary",
  "eu-west-1": "Ireland",
  "italy": "Italy",
  "latvia": "Latvia",
  "liechtenstein": "Liechtenstein",
  "lithuania": "Lithuania",
  "luxembourg": "Luxembourg",
  "moldova": "Moldova",
  "monaco": "Monaco",
  "netherlands-central": "Netherlands Central",
  "netherlands-south": "Netherlands South",
  "norway": "Norway",
  "poland": "Poland",
  "portugal": "Portugal",
  "romania": "Romania",
  "russia-central": "Russia Central",
  "russia-northwest":"Russia Northwest",
  "slovakia": "Slovakia",
  "slovenia": "Slovenia",
  "spain-central": "Spain Central",
  "spain-east": "Spain East",
  "sweden": "Sweden",
  "switzerland": "Switzerland",
  "uganda": "Uganda",
  "eu-west-2": "UK",
  "ukraine": "Ukraine",
  "uzbekistan": "Uzbekistan",
  "me-south-1": "Bahrain",
  "egypt": "Egypt",
  "israel": "Israel",
  "jordan": "Jordan",
  "kuwait": "Kuwait",
  "qatar": "Qatar",
  "saudi-arabia": "Saudi Arabia",
  "turkey": "Turkey",
  "uae": "United Arab Emirates",
  "kenya": "Kenya",
  "nigeria": "Nigeria",
  "senegal": "Senegal",
  "south-africa-central": "South Africa Central",
  "south-africa-west": "South Africa West",
  "bangladesh": "Bangladesh",
  "cambodia": "Cambodia",
  "hong-kong": "Hong Kong",
  "india-north": "India North",
  "india-south": "India South",
  "ap-south-1": "India West",
  "indonesia": "Indonesia",
  "kazakhstan": "Kazakhstan",
  "malaysia": "Malaysia",
  "myanmar": "Myanmar",
  "pakistan-south": "Pakistan South",
  "pakistan-west": "Pakistan West",
  "pakistan-west-2": "Pakistan West (II)",
  "papua-new-guinea": "Papua New Guinea",
  "philippines": "Philippines",
  "ap-southeast-1": "Singapore",
  "ap-northeast-2": "South Korea",
  "srilanka": "Sri Lanka",
  "taiwan": "Taiwan",
  "thailand": "Thailand",
  "vietnam": "Vietnam",
  "ap-northeast-1": "Japan Central",
  "japan-south": "Japan South",
  "australia-east": "Australia East",
  "australia-south": "Australia South",
  "ap-southeast-2": "Australia Southeast",
  "new-zealand": "New Zealand",
}